@font-face {
  font-family: 'MontserratLight';
  src: url('./../fonts/MontserratLight/MontserratLight.eot');
  src: url('./../fonts/MontserratLight/MontserratLight.eot') format('embedded-opentype'),
       url('./../fonts/MontserratLight/MontserratLight.woff2') format('woff2'),
       url('./../fonts/MontserratLight/MontserratLight.woff') format('woff'),
       url('./../fonts/MontserratLight/MontserratLight.ttf') format('truetype'),
       url('./../fonts/MontserratLight/MontserratLight.svg#MontserratLight') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'AveriaSerifLibreLight';
  src: url('./../fonts/AveriaSerifLibreLight/AveriaSerifLibreLight.eot');
  src: url('./../fonts/AveriaSerifLibreLight/AveriaSerifLibreLight.eot') format('embedded-opentype'),
       url('./../fonts/AveriaSerifLibreLight/AveriaSerifLibreLight.woff2') format('woff2'),
       url('./../fonts/AveriaSerifLibreLight/AveriaSerifLibreLight.woff') format('woff'),
       url('./../fonts/AveriaSerifLibreLight/AveriaSerifLibreLight.ttf') format('truetype'),
       url('./../fonts/AveriaSerifLibreLight/AveriaSerifLibreLight.svg#AveriaSerifLibreLight') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'AleoLight';
  src: url('./../fonts/AleoLight/AleoLight.eot');
  src: url('./../fonts/AleoLight/AleoLight.eot') format('embedded-opentype'),
       url('./../fonts/AleoLight/AleoLight.woff2') format('woff2'),
       url('./../fonts/AleoLight/AleoLight.woff') format('woff'),
       url('./../fonts/AleoLight/AleoLight.ttf') format('truetype'),
       url('./../fonts/AleoLight/AleoLight.svg#AleoLight') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'NixieOneRegular';
  src: url('./../fonts/NixieOneRegular/NixieOneRegular.eot');
  src: url('./../fonts/NixieOneRegular/NixieOneRegular.eot') format('embedded-opentype'),
       url('./../fonts/NixieOneRegular/NixieOneRegular.woff2') format('woff2'),
       url('./../fonts/NixieOneRegular/NixieOneRegular.woff') format('woff'),
       url('./../fonts/NixieOneRegular/NixieOneRegular.ttf') format('truetype'),
       url('./../fonts/NixieOneRegular/NixieOneRegular.svg#NixieOneRegular') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'OpenSansLight';
  src: url('./../fonts/OpenSansLight/OpenSansLight.eot');
  src: url('./../fonts/OpenSansLight/OpenSansLight.eot') format('embedded-opentype'),
       url('./../fonts/OpenSansLight/OpenSansLight.woff2') format('woff2'),
       url('./../fonts/OpenSansLight/OpenSansLight.woff') format('woff'),
       url('./../fonts/OpenSansLight/OpenSansLight.ttf') format('truetype'),
       url('./../fonts/OpenSansLight/OpenSansLight.svg#OpenSansLight') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'NTRRegular';
  src: url('./../fonts/NTRRegular/NTRRegular.eot');
  src: url('./../fonts/NTRRegular/NTRRegular.eot') format('embedded-opentype'),
       url('./../fonts/NTRRegular/NTRRegular.woff2') format('woff2'),
       url('./../fonts/NTRRegular/NTRRegular.woff') format('woff'),
       url('./../fonts/NTRRegular/NTRRegular.ttf') format('truetype'),
       url('./../fonts/NTRRegular/NTRRegular.svg#NTRRegular') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'GochiHandRegular';
  src: url('./../fonts/GochiHandRegular/GochiHandRegular.eot');
  src: url('./../fonts/GochiHandRegular/GochiHandRegular.eot') format('embedded-opentype'),
       url('./../fonts/GochiHandRegular/GochiHandRegular.woff2') format('woff2'),
       url('./../fonts/GochiHandRegular/GochiHandRegular.woff') format('woff'),
       url('./../fonts/GochiHandRegular/GochiHandRegular.ttf') format('truetype'),
       url('./../fonts/GochiHandRegular/GochiHandRegular.svg#GochiHandRegular') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'FingerPaintRegular';
  src: url('./../fonts/FingerPaintRegular/FingerPaintRegular.eot');
  src: url('./../fonts/FingerPaintRegular/FingerPaintRegular.eot') format('embedded-opentype'),
       url('./../fonts/FingerPaintRegular/FingerPaintRegular.woff2') format('woff2'),
       url('./../fonts/FingerPaintRegular/FingerPaintRegular.woff') format('woff'),
       url('./../fonts/FingerPaintRegular/FingerPaintRegular.ttf') format('truetype'),
       url('./../fonts/FingerPaintRegular/FingerPaintRegular.svg#FingerPaintRegular') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'AbrilFatfaceRegular_0';
  src: url('./../fonts/AbrilFatfaceRegular_0/AbrilFatfaceRegular_0.eot');
  src: url('./../fonts/AbrilFatfaceRegular_0/AbrilFatfaceRegular_0.eot') format('embedded-opentype'),
       url('./../fonts/AbrilFatfaceRegular_0/AbrilFatfaceRegular_0.woff2') format('woff2'),
       url('./../fonts/AbrilFatfaceRegular_0/AbrilFatfaceRegular_0.woff') format('woff'),
       url('./../fonts/AbrilFatfaceRegular_0/AbrilFatfaceRegular_0.ttf') format('truetype'),
       url('./../fonts/AbrilFatfaceRegular_0/AbrilFatfaceRegular_0.svg#AbrilFatfaceRegular_0') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
    font-family: 'RubikLight';
    src: url('./../fonts/RubikLight/RubikLight.eot');
    src: url('./../fonts/RubikLight/RubikLight.eot') format('embedded-opentype'),
         url('./../fonts/RubikLight/RubikLight.woff2') format('woff2'),
         url('./../fonts/RubikLight/RubikLight.woff') format('woff'),
         url('./../fonts/RubikLight/RubikLight.ttf') format('truetype'),
         url('./../fonts/RubikLight/RubikLight.svg#RubikLight') format('svg');
  font-weight: normal;
  font-style: normal;
}

body{
  color: #212121;
  font-size: 16px;
  line-height: (24em/16);
  padding: 0;
  margin: 0;
}
a{
  color: #212121;
  text-decoration: none;
}
.container-center{
  height: 100vh;
  padding-left: 20px;
  padding-right: 20px;
  max-width: 370px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  @media screen and (max-width: 700px) {
    max-width: 300px;
  }
}
.js .container-center{
  opacity: 0;
  transition: opacity 0.6s ease;
}
.js.html-loaded .container-center{
  opacity: 1;
}
.container{
  height: 170px;
  position: absolute;
  top: 50%;
  left: 50px;
  right: 50px;
  transform: translate(0, -50%);
  text-align: center;
  font-family: 'MontserratLight';
  opacity: 1;
  margin-top: -45px;
  &.hidden{
    opacity: 0;
  }
  .text-body{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    transition: opacity 0.2s ease;
    opacity: 0;
    &.active{
      opacity: 1;
    }
  }
}
h1{
  font-weight: normal;
  font-size: 26px;
  margin: 0 0 8px 0;

}
h2{
  font-size: 16px;
  font-weight: normal;
  margin: 8px 0 16px 0;
  span{
    white-space: nowrap;
  }
}
.contact-info{
  margin: 16px 0 0 0;
  span{
    display: block;
  }
  a{
    display: inline-block;
    position: relative;
    &:after{
      content: '';
      position: absolute;
      top: 100%;
      left: 50%;
      right: 50%;
      height: 1px;
      background: #212121;
      transition: all 0.2s ease;
    }
    &:hover{
      &:after{
        left: 0;
        right: 0;
      }
    }
  }
}

body.montserrat{
  h1{
    text-transform: uppercase;
  }
}

.text-body.averia{
  font-family: 'AleoLight';
  h1{
    font-family: 'AveriaSerifLibreLight';
    text-transform: uppercase;
  }
  h2{
    font-family: 'AleoLight';
  }
}

.text-body.nixie{
  font-family: 'OpenSansLight';
  h1{
    font-family: 'NixieOneRegular';
  }
  h2{
    font-family: 'OpenSansLight';
  }
}

.text-body.finger{
  font-family: 'GochiHandRegular';
  font-size: 18px;
  h1{
    font-family: 'FingerPaintRegular';
    text-transform: uppercase;
  }
  h2{
    font-family: 'NTRRegular';
    font-size: 22px;
  }
}

.text-body.abril{
  font-family: 'RubikLight';
  h1{
    font-family: 'AbrilFatfaceRegular_0';
    font-size: 30px;
  }
  h2{
    font-family: 'RubikLight';
  }
}

.paginator{
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  text-align: center;
  margin-top: 128px;
  transform: translate(0, -50%);
  ul{
    display: inline-block;
    margin: 0 auto 40px auto;
    list-style: none;
    padding: 0;
    border-bottom: 1px solid #212121;
    overflow: hidden;
    li{
      float: left;
      + li{
        padding-left: 18px;
      }
      a{
        display: block;
        width: 29px;
        height: 41px;
        text-align: center;
        font-size: 27px;
        position: relative;
        &:after{
          content: '';
          display: block;
          position: absolute;
          left: 0;
          right: 0;
          bottom: -4px;
          height: 4px;
          background: #212121;
          transition: all 0.2s ease;
        }
        &.montserrat{
          font-family: 'MontserratLight';
        }
        &.averia{
          font-family: 'AveriaSerifLibreLight';
          &:after{
            font-family: 'AleoLight';
            content: 'a';
          }
        }
        &.nixie{
          font-family: 'NixieOneRegular';
          &:after{
            font-family: 'OpenSansLight';
            content: 'a';
          }
        }
        &.finger{
          font-family: 'FingerPaintRegular';
          &:after{
            font-family: 'GochiHandRegular';
            content: 'a';
          }
          &:before{
            font-family: 'NTRRegular';
            content: 'a';
            width: 0;
            height: 0;
            display: block;
            overflow: hidden;
          }
        }
        &.abril{
          font-family: 'AbrilFatfaceRegular_0';
          &:after{
            font-family: 'RubikLight';
            content: 'a';
          }
        }
        &.active{
          &:after{
            bottom: 0;
          }
        }
      }
    }
  }
}


.night{
  body{
    background: #222326;
    color: #FFF;
  }
  a{
    color: #FFF;
  }
  .contact-info{
    a{
      &:after{
        background: #FFF;
      }
    }
  }
  .paginator{
    ul{
      border-bottom: 1px solid #FFF;
      li{
        a{
          &:after{
            background: #FFF;
          }
        }
      }
    }
  }
}